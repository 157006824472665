<template>
  <div>
    <v-tabs v-model="tabs" background-color="rgb(243,243,243)" grow>
      <v-tabs-slider></v-tabs-slider>
      <v-tab href="#salary">급여 ( {{ selectedSalaryList.length }} )</v-tab>
      <v-tab href="#sales"> 매출 ( {{ selectedSalesList.length }} )</v-tab>
      <v-tab href="#etc_income">
        기타소득 ( {{ selectedEtcIncomeList.length }} )
      </v-tab>
      <v-tab href="#etc_deposit">
        추가입금 ( {{ selectedEtcDepositList.length }} )
      </v-tab>
      <v-tab href="#total_income">
        소득집계 (
        {{
          selectedSalaryList.length +
          selectedSalesList.length +
          selectedEtcIncomeList.length +
          selectedEtcDepositList.length
        }}
        )
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tabs">
      <v-tab-item value="salary">
        <div class="income-base-padding" style="min-height: 100vh;">
          <salary-table
            ref="salaryTable"
            v-bind="{
              baseDate: baseDate,
              isCanSelected: true,
              titleText: '급여 정보분류',
              titleTextClass: 'text-seizeblue',
              detailData: salaryDataList,
              filterDisabled: {
                term: false,
                inout: true,
                targetInst: true,
                name: true,
                level2: true,
                level3: true,
                level4: true,
                level5: true,
                tranType: true,
              },
              termInitialFilter: ['1M'],
              inoutInitialFilter: ['입금'],
              itemsPerPage: 10,
            }"
            @data-selected="salaryItemSelected"
            @data-selected-all="salaryItemSelectedAll"
          >
          </salary-table>
        </div>
      </v-tab-item>
      <v-tab-item value="sales">
        <div class="income-base-padding" style="min-height: 100vh;">
          <sales-table
            ref="salesTable"
            v-bind="{
              baseDate: baseDate,
              isCanSelected: true,
              titleText: '매출 정보분류',
              titleTextClass: 'text-seizeblue',
              detailData: salesDataList,
              filterDisabled: {
                term: false,
                inout: true,
                targetInst: true,
                name: true,
                level2: true,
                level3: true,
                level4: true,
                level5: true,
                tranType: true,
              },
              termInitialFilter: ['1M'],
              inoutInitialFilter: ['입금'],
              itemsPerPage: 10,
            }"
            @data-selected="salesItemSelected"
            @data-selected-all="salesItemSelectedAll"
          >
          </sales-table>
        </div>
      </v-tab-item>
      <v-tab-item value="etc_income">
        <div class="income-base-padding" style="min-height: 100vh;">
          <etc-income-table
            ref="etcIncomeTable"
            v-bind="{
              baseDate: baseDate,
              isCanSelected: true,
              titleText: '기타소득 정보분류',
              titleTextClass: 'text-seizeblue',
              detailData: etcIncomeDataList,
              filterDisabled: {
                term: false,
                inout: true,
                targetInst: true,
                name: true,
                level2: true,
                level3: true,
                level4: true,
                level5: true,
                tranType: true,
              },
              termInitialFilter: ['1M'],
              inoutInitialFilter: ['입금'],
              itemsPerPage: 10,
            }"
            @data-selected="etcIncomeItemSelected"
            @data-selected-all="etcIncomeItemSelectedAll"
          >
          </etc-income-table>
        </div>
      </v-tab-item>
      <v-tab-item value="etc_deposit">
        <div class="income-base-padding" style="min-height: 100vh;">
          <etc-deposit-table
            ref="etcDepositTable"
            v-bind="{
              baseDate: baseDate,
              isCanSelected: true,
              titleText: '추가입금 정보분류',
              titleTextClass: 'text-seizeblue',
              detailData: etcDepositDataList,
              filterDisabled: {
                term: false,
                inout: true,
                targetInst: true,
                name: true,
                level2: true,
                level3: true,
                level4: true,
                level5: true,
                tranType: true,
              },
              termInitialFilter: ['1M'],
              inoutInitialFilter: ['입금'],
              itemsPerPage: 10,
            }"
            @data-selected="etcDepositItemSelected"
            @data-selected-all="etcDepositItemSelectedAll"
          >
          </etc-deposit-table>
        </div>
      </v-tab-item>
      <v-tab-item value="total_income">
        <div class="income-base-padding" style="min-height: 100vh;">
          <div class="basic-table">
            <div class="basic-table__header">
              <h3 class="basic-table__title text-seizeblue">소득 집계</h3>
            </div>
            <div class="basic-table__table">
              <v-data-table
                :headers="groupHeaders"
                :items="totalIncomeData.lists"
                class="elevation-1"
                hide-default-footer
                item-key="index"
                loading-text="데이터 조회중입니다..."
                multi-sort
                show-expand
              >
                <template v-slot:item.count="{ item }">
                  {{ getValueData(item.count) + "건" }}
                </template>
                <template v-slot:item.sum="{ item }">
                  {{ getValueData(item.sum) }}
                </template>

                <template v-slot:expanded-item="{ headers, item }">
                  <td colspan="1"></td>
                  <td colspan="6">
                    <v-data-table
                      :headers="detailHeaders"
                      :items="item.object_list"
                      :items-per-page="item.itemsPerPage"
                      :page.sync="item.page"
                      class="elevation-1"
                      hide-default-footer
                      item-key="no"
                      multi-sort
                      show-expand
                      @page-count="item.pageCount = $event"
                    >
                      <template v-slot:item.tran_date="{ item }">
                        {{ getDateString(item.tran_date) }}
                      </template>
                      <template v-slot:item.month="{ item }">
                        {{ getMonthString(item.tran_date) }}
                      </template>
                      <template v-slot:item.diff_month="{ item }">
                        {{ getDiffMonthString(item.tran_date) }}
                      </template>
                      <template v-slot:item.tran_amt="{ item }">
                        {{ getValueData(item.tran_amt) }}
                      </template>
                      <template v-slot:item.after_balance_amt="{ item }">
                        {{ getValueData(item.after_balance_amt) }}
                      </template>
                      <template v-slot:expanded-item="{ headers, item }">
                        <td colspan="2"></td>
                        <td colspan="4">
                          <v-list dense>
                            <v-list-item>
                              <v-list-item-content class="seize-filter-text"
                                >계좌번호
                              </v-list-item-content>
                              <v-list-item-content class="align-end"
                                >{{ item.account_num }}
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                              <v-list-item-content class="seize-filter-text"
                                >거래시간
                              </v-list-item-content>
                              <v-list-item-content class="align-end"
                                >{{ item.tran_date }}
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                              <v-list-item-content class="seize-filter-text"
                                >거래유형
                              </v-list-item-content>
                              <v-list-item-content class="align-end"
                                >{{ item.tran_type }}
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                              <v-list-item-content class="seize-filter-text"
                                >정보분류 레벨2
                              </v-list-item-content>
                              <v-list-item-content class="align-end"
                                >{{ item.level2 }}
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                              <v-list-item-content class="seize-filter-text"
                                >정보분류 레벨3
                              </v-list-item-content>
                              <v-list-item-content class="align-end"
                                >{{ item.level3 }}
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                              <v-list-item-content class="seize-filter-text"
                                >정보분류 레벨4
                              </v-list-item-content>
                              <v-list-item-content class="align-end"
                                >{{ item.level4 }}
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                              <v-list-item-content class="seize-filter-text"
                                >정보분류 레벨5
                              </v-list-item-content>
                              <v-list-item-content class="align-end"
                                >{{ item.level5 }}
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-content class="seize-filter-text"
                                >대상기관명
                              </v-list-item-content>
                              <v-list-item-content class="align-end"
                                >{{ item.target_inst_name }}
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-content class="seize-filter-text"
                                >성명
                              </v-list-item-content>
                              <v-list-item-content class="align-end"
                                >{{ item.capt_name }}
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-content class="seize-filter-text"
                                >회차
                              </v-list-item-content>
                              <v-list-item-content class="align-end"
                                >{{ item.capt_round }}
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-content class="seize-filter-text"
                                >건수
                              </v-list-item-content>
                              <v-list-item-content class="align-end"
                                >{{ item.capt_count }}
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-content class="seize-filter-text"
                                >지점
                              </v-list-item-content>
                              <v-list-item-content class="align-end"
                                >{{ item.capt_branch }}
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </td>
                        <td colspan="5"></td>
                      </template>
                    </v-data-table>
                    <b-container style="margin-top: 10px; margin-bottom: 10px;">
                      <!-- Stack the columns on mobile by making one full-width and the other half-width -->
                      <b-row align-v="center">
                        <b-col cols="10">
                          <v-pagination
                            v-model="item.page"
                            :length="item.pageCount"
                            :next-icon="'mdi-menu-right'"
                            :prev-icon="'mdi-menu-left'"
                            :total-visible="10"
                          ></v-pagination>
                        </b-col>
                        <b-col class="text-right" cols="1" style="padding: 0;">
                          <b-form-select
                            v-model="item.itemsPerPage"
                            :options="pageOptions"
                          ></b-form-select>
                        </b-col>
                        <b-col class="text-right" cols="1" style="padding: 0;">
                          <span>{{ item.total_count }}</span>
                        </b-col>
                      </b-row>
                    </b-container>
                  </td>
                </template>
              </v-data-table>
            </div>
          </div>
          <v-row dense>
            <v-col cols="1"></v-col>
            <v-col cols="4">
              <v-card color="#FFF6E3">
                <v-card-subtitle
                  class="text-seizeorange"
                  style="font-size: 1.2rem;"
                >
                  <div class="text-center" style="width: 100%;">
                    월 소득 수령액
                  </div>
                </v-card-subtitle>
                <v-card-title class="text-seizeorange" style="font-size: 2rem;">
                  <div class="text-center" style="width: 100%;">
                    {{ getValueData(totalIncomeData.totalAvg) + "원" }}
                  </div>
                </v-card-title>
              </v-card>
            </v-col>
            <v-col cols="2"></v-col>
            <v-col cols="4">
              <v-card color="#FFE2E5">
                <v-card-subtitle
                  class="text-seizered"
                  style="font-size: 1.2rem;"
                >
                  <div class="text-center" style="width: 100%;">
                    연 소득 수령액
                  </div>
                </v-card-subtitle>
                <v-card-title class="text-seizered" style="font-size: 2rem;">
                  <div class="text-center" style="width: 100%;">
                    {{ getValueData(totalIncomeData.totalAvg * 12) + "원" }}
                  </div>
                </v-card-title>
              </v-card>
            </v-col>
            <v-col cols="1"></v-col>
          </v-row>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import TransactionDataTable from "@/views/partials/widgets/tables/TransactionDataTable";
import DataController from "@/common/utils/data";
import numeral from "@/common/utils/numeral";
import SeizePlugin from "@/common/utils/seize";
import Vue from "vue";

Vue.use(SeizePlugin);
export default {
  name: "Income",
  components: {
    "salary-table": TransactionDataTable,
    "sales-table": TransactionDataTable,
    "etc-income-table": TransactionDataTable,
    "etc-deposit-table": TransactionDataTable,
  },
  data() {
    return {
      baseDate: this.$store.getters.baseDate,
      totalAvgIncome: 0,
      salaryDataList: [],
      selectedSalaryList: [],
      salesDataList: [],
      selectedSalesList: [],
      etcIncomeDataList: [],
      selectedEtcIncomeList: [],
      etcDepositDataList: [],
      selectedEtcDepositList: [],
      pageOptions: [
        { value: 10, text: "10", selected: true },
        { value: 20, text: "20" },
        { value: 30, text: "30" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
      ],
      groupHeaders: [
        {
          text: "그룹",
          align: "center",
          class: "seize-table-header",
          sortable: false,
          value: "name",
        },
        {
          text: "기간",
          align: "center",
          class: "seize-table-header",
          sortable: false,
          value: "period",
        },
        {
          text: "건수",
          align: "center",
          class: "seize-table-header",
          sortable: false,
          value: "count",
        },
        {
          text: "금액합계",
          align: "right",
          class: "seize-table-header",
          sortable: false,
          value: "sum",
        },
        {
          text: "월평균금액",
          align: "right",
          class: "seize-table-header",
          sortable: false,
          value: "avg",
        },
        {
          text: "금액합계",
          align: "right",
          class: "seize-table-header",
          sortable: false,
          value: "one_price",
        },
      ],
      detailHeaders: [
        {
          text: "은행명",
          align: "center",
          class: "seize-table-header",
          value: "bank_name",
        },
        {
          text: "거래일",
          align: "center",
          class: "seize-table-header",
          value: "tran_date",
        },
        {
          text: "월",
          align: "center",
          class: "seize-table-header",
          sortable: false,
          value: "month",
        },
        {
          text: "기간",
          align: "center",
          class: "seize-table-header",
          sortable: false,
          value: "diff_month",
        },
        {
          text: "입출구분",
          align: "center",
          class: "seize-table-header",
          value: "inout_type",
        },
        {
          text: "거래내용",
          align: "start",
          class: "seize-table-header",
          value: "print_content",
        },
        {
          text: "거래금액",
          align: "right",
          class: "seize-table-header",
          value: "tran_amt",
        },
        {
          text: "잔액",
          align: "right",
          class: "seize-table-header",
          value: "after_balance_amt",
        },
        {
          text: "정보분류 설명",
          align: "right",
          class: "seize-table-header",
          value: "level_concat",
        },
      ],
      tabs: null,
    };
  },
  mounted() {
    document.title = "소득 확인";
    this.initializeData();
  },
  methods: {
    updateView() {
      this.baseDate = this.$store.getters.baseDate;
      this.initializeData();
      if (this.$refs.salaryTable)
        this.$refs.salaryTable.setData(this.baseDate, this.salaryDataList);
      if (this.$refs.salesTable)
        this.$refs.salesTable.setData(this.baseDate, this.salesDataList);
      if (this.$refs.etcIncomeTable)
        this.$refs.etcIncomeTable.setData(
          this.baseDate,
          this.etcIncomeDataList
        );
      if (this.$refs.etcDepositTable)
        this.$refs.etcDepositTable.setData(
          this.baseDate,
          this.etcDepositDataList
        );
    },
    initializeData() {
      const dataController = new DataController();
      dataController.setIncomeInfoData(
        this.baseDate,
        this.$store.getters.requestItem.items
      );
      this.salaryDataList = dataController.rawData.filter(
        (data) => data.major_info === "급여"
      );
      this.salesDataList = dataController.rawData.filter(
        (data) => data.major_info === "매출"
      );
      this.etcIncomeDataList = dataController.rawData.filter(
        (data) => data.major_info === "기타소득"
      );
      this.etcDepositDataList = dataController.rawData.filter(
        (data) =>
          data.major_info !== "급여" &&
          data.major_info !== "매출" &&
          data.major_info !== "기타소득"
      );
      this.totalAvgIncome = 0;
      this.selectedSalaryList = [];
      this.selectedSalesList = [];
      this.selectedEtcIncomeList = [];
      this.selectedEtcDepositList = [];
    },
    salaryItemSelected: function (event) {
      if (event.value) {
        this.selectedSalaryList.push(event.item);
      } else {
        const idx = this.selectedSalaryList.findIndex(function (item) {
          return item.id === event.item.id;
        });
        this.selectedSalaryList.splice(idx, 1);
      }
    },
    salaryItemSelectedAll: function (event) {
      if (event.value) {
        event.items.forEach((element) => {
          const idx = this.selectedSalaryList.findIndex(function (item) {
            return item.id === element.id;
          });
          if (idx === -1) {
            this.selectedSalaryList.push(element);
          }
        });
      } else {
        event.items.forEach((element) => {
          const idx = this.selectedSalaryList.findIndex(function (item) {
            return item.id === element.id;
          });
          this.selectedSalaryList.splice(idx, 1);
        });
      }
    },
    salesItemSelected: function (event) {
      if (event.value) {
        this.selectedSalesList.push(event.item);
      } else {
        const idx = this.selectedSalesList.findIndex(function (item) {
          return item.id === event.item.id;
        });
        this.selectedSalesList.splice(idx, 1);
      }
    },
    salesItemSelectedAll: function (event) {
      if (event.value) {
        event.items.forEach((element) => {
          const idx = this.selectedSalesList.findIndex(function (item) {
            return item.id === element.id;
          });
          if (idx === -1) {
            this.selectedSalesList.push(element);
          }
        });
      } else {
        event.items.forEach((element) => {
          const idx = this.selectedSalesList.findIndex(function (item) {
            return item.id === element.id;
          });
          this.selectedSalesList.splice(idx, 1);
        });
      }
    },
    etcIncomeItemSelected: function (event) {
      if (event.value) {
        this.selectedEtcIncomeList.push(event.item);
      } else {
        const idx = this.selectedEtcIncomeList.findIndex(function (item) {
          return item.id === event.item.id;
        });
        this.selectedEtcIncomeList.splice(idx, 1);
      }
    },
    etcIncomeItemSelectedAll: function (event) {
      if (event.value) {
        event.items.forEach((element) => {
          const idx = this.selectedEtcIncomeList.findIndex(function (item) {
            return item.id === element.id;
          });
          if (idx === -1) {
            this.selectedEtcIncomeList.push(element);
          }
        });
      } else {
        event.items.forEach((element) => {
          const idx = this.selectedEtcIncomeList.findIndex(function (item) {
            return item.id === element.id;
          });
          this.selectedEtcIncomeList.splice(idx, 1);
        });
      }
    },
    etcDepositItemSelected: function (event) {
      if (event.value) {
        this.selectedEtcDepositList.push(event.item);
      } else {
        const idx = this.selectedEtcDepositList.findIndex(function (item) {
          return item.id === event.item.id;
        });
        this.selectedEtcDepositList.splice(idx, 1);
      }
    },
    etcDepositItemSelectedAll: function (event) {
      if (event.value) {
        event.items.forEach((element) => {
          const idx = this.selectedEtcDepositList.findIndex(function (item) {
            return item.id === element.id;
          });
          if (idx === -1) {
            this.selectedEtcDepositList.push(element);
          }
        });
      } else {
        event.items.forEach((element) => {
          const idx = this.selectedEtcDepositList.findIndex(function (item) {
            return item.id === element.id;
          });
          this.selectedEtcDepositList.splice(idx, 1);
        });
      }
    },
    getDateString(date) {
      return Vue.dateToDateString(date);
    },
    getMonthString(date) {
      return Vue.dateToMonthString(date);
    },
    getDiffMonthString(date) {
      return Vue.diffMonthString(date, this.baseDate);
    },
    getValueData(value) {
      return numeral(value).format("0,0");
    },
  },
  computed: {
    totalIncomeData: function () {
      const salary = {
        index: 0,
        name: "급여",
        period: "",
        count: "0건",
        sum: "0",
        avg: "0",
        one_price: "0",
        object_list: this.selectedSalaryList,
        page: 1,
        itemsPerPage: 10,
        pageCount: 0,
        total_count:
          "총 " + String(this.selectedSalaryList.length) + "개 데이터",
      };
      const sales = {
        index: 1,
        name: "매출",
        period: "",
        count: "0건",
        sum: "0",
        avg: "0",
        one_price: "0",
        object_list: this.selectedSalesList,
        page: 1,
        itemsPerPage: 10,
        pageCount: 0,
        total_count:
          "총 " + String(this.selectedSalesList.length) + "개 데이터",
      };
      const etcIncome = {
        index: 2,
        name: "기타소득",
        period: "",
        count: "0건",
        sum: "0",
        avg: "0",
        one_price: "0",
        object_list: this.selectedEtcIncomeList,
        page: 1,
        itemsPerPage: 10,
        pageCount: 0,
        total_count:
          "총 " + String(this.selectedEtcIncomeList.length) + "개 데이터",
      };
      const etcDeposit = {
        index: 3,
        name: "추가입금",
        period: "",
        count: "0건",
        sum: "0",
        avg: "0",
        one_price: "0",
        object_list: this.selectedEtcDepositList,
        page: 1,
        itemsPerPage: 10,
        pageCount: 0,
        total_count:
          "총 " + String(this.selectedEtcDepositList.length) + "개 데이터",
      };
      let period = [];
      let count = 0,
        sum = 0;
      this.selectedSalaryList.forEach((element) => {
        const idx = period.indexOf(element.diff_month);
        if (idx === -1) {
          period.push(element.diff_month);
        }
        count += 1;
        sum += element.tran_amt;
      });
      salary.period = String(period);
      salary.count = String(count) + "건";
      salary.sum = numeral(sum).format("0,0");
      salary.avg =
        period.length === 0
          ? "0"
          : numeral(Math.round(sum / period.length)).format("0,0");
      salary.one_price =
        count === 0 ? "0" : numeral(Math.round(sum / count)).format("0,0");

      period = [];
      count = 0;
      sum = 0;
      this.selectedSalesList.forEach((element) => {
        const idx = period.indexOf(element.diff_month);
        if (idx === -1) {
          period.push(element.diff_month);
        }
        count += 1;
        sum += element.tran_amt;
      });
      sales.period = String(period);
      sales.count = String(count) + "건";
      sales.sum = numeral(sum).format("0,0");
      sales.avg =
        period.length === 0
          ? "0"
          : numeral(Math.round(sum / period.length)).format("0,0");
      sales.one_price =
        count === 0 ? "0" : numeral(Math.round(sum / count)).format("0,0");

      period = [];
      count = 0;
      sum = 0;
      this.selectedEtcIncomeList.forEach((element) => {
        const idx = period.indexOf(element.diff_month);
        if (idx === -1) {
          period.push(element.diff_month);
        }
        count += 1;
        sum += element.tran_amt;
      });
      etcIncome.period = String(period);
      etcIncome.count = String(count) + "건";
      etcIncome.sum = numeral(sum).format("0,0");
      etcIncome.avg =
        period.length === 0
          ? "0"
          : numeral(Math.round(sum / period.length)).format("0,0");
      etcIncome.one_price =
        count === 0 ? "0" : numeral(Math.round(sum / count)).format("0,0");

      period = [];
      count = 0;
      sum = 0;
      this.selectedEtcDepositList.forEach((element) => {
        const idx = period.indexOf(element.diff_month);
        if (idx === -1) {
          period.push(element.diff_month);
        }
        count += 1;
        sum += element.tran_amt;
      });
      etcDeposit.period = String(period);
      etcDeposit.count = String(count) + "건";
      etcDeposit.sum = numeral(sum).format("0,0");
      etcDeposit.avg =
        period.length === 0
          ? "0"
          : numeral(Math.round(sum / period.length)).format("0,0");
      etcDeposit.one_price =
        count === 0 ? "0" : numeral(Math.round(sum / count)).format("0,0");
      const result = {
        totalAvg:
          numeral(salary.avg).value() +
          numeral(sales.avg).value() +
          numeral(etcIncome.avg).value() +
          numeral(etcDeposit.avg).value(),
        lists: [],
      };
      result.lists.push(salary);
      result.lists.push(sales);
      result.lists.push(etcIncome);
      result.lists.push(etcDeposit);
      return result;
    },
  },
};
</script>

<style scoped></style>
